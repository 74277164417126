import { useState } from 'react';
import { Message, getMessage } from '../data/messages';
import { Podcast, getPodcast } from '../data/podcasts';
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonPage,
  IonToolbar,
  useIonViewWillEnter,
  IonButton,
} from '@ionic/react';
import { useParams } from 'react-router';
import './ViewMessage.css';

function ViewMessage() {
  const [message, setMessage] = useState<Message>();
  const [podcast, setPodcast] = useState<Podcast>();
  const params = useParams<{ id: string }>();

  useIonViewWillEnter(async () => {
    const msg = getMessage(parseInt(params.id, 10));
    setMessage(msg);
    const pod = await getPodcast(params.id);
    setPodcast(pod);
  });

  return (
    <IonPage id="view-message-page">
      <IonHeader translucent>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton text="Podcast Data" defaultHref="/home"></IonBackButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>

        <IonCard>
          <IonCardHeader style={{ textAlign: "center" }}>
            <img src={podcast?.thumbnail} width="40%" />
            <IonCardSubtitle>{podcast?.podcast}</IonCardSubtitle>
            <IonCardTitle>{podcast?.episode}</IonCardTitle>
          </IonCardHeader>
          <div className='mediaActionButtons'>
            <IonButton>Add to queue</IonButton>
            <IonButton>Play Now</IonButton>
          </div>

        </IonCard>
      </IonContent>
    </IonPage>
  );
}

export default ViewMessage;
