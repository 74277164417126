
import PodcastItemList from '../components/PodcastListItem';
import { useEffect, useState } from 'react';
import { Podcast, getAllPodcasts, getRecommendedPodcasts } from '../data/podcasts';
import {
  IonContent,
  IonHeader,
  IonList,
  IonPage,
  IonTitle,
  IonToolbar,
  useIonViewWillEnter,
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
} from '@ionic/react';
import { PlayCircleOutline, PlayBackCircleOutline, PlayForwardCircleOutline, PauseCircleOutline } from 'react-ionicons'
import Fuse from 'fuse.js'
import './Home.css';

const Home: React.FC = () => {
  const [recc_podcasts, setReccPodcasts] = useState<Podcast[]>([]);
  const [allPodcasts, setAllPodcasts] = useState<Podcast[]>([])
  const [podcasts, setPodcasts] = useState<Podcast[]>([{ podcast: "test", episode: "test", thumbnail: "notanimg.jpeg", pid: "-1" }]);
  const [isPlay, setIsPlay] = useState<Boolean>(true)
  const [currentPodcast, setCurrentPodcast] = useState<number>(0)
  const [searchInput, setSearchInput] = useState<string>("")
  const getRecommended = async () => {
    const recc_pods = await getRecommendedPodcasts(currentPodcast.toString(), 6)
    setReccPodcasts(recc_pods)
    setPodcasts(recc_podcasts)
  }

  const nextPod = () => {
    setCurrentPodcast((currentPodcast + 1) % allPodcasts.length)
  }

  const prevPod = () => {
    let np = (currentPodcast - 1) % allPodcasts.length
    if(np < 0){
      np = allPodcasts.length - 1
    }
    console.log(np)
    setCurrentPodcast(np)
  }

  useEffect(() => {
    getRecommended()
  }, [currentPodcast])


  useEffect(() => {
    if(searchInput === ""){
      setPodcasts(allPodcasts)
    }else{
      const options = {
        includeScore: false,
        // Search in `author` and in `tags` array
        keys: ['podcast', 'episode']
      }
      const fuse = new Fuse(allPodcasts, options)
      const result = fuse.search(searchInput)
      let matchedPods : Podcast[] = []
      result.forEach(r => {
        matchedPods.push(r.item)
      })
     
      setPodcasts(matchedPods)
    }
 
   
    
  },[searchInput])

  useIonViewWillEnter(async () => {
    const allPods = await getAllPodcasts()
    setAllPodcasts(allPods)
    setPodcasts(allPods)
  });

  return (
    <IonPage id="home-page" >
      <IonHeader>
        <IonToolbar>
          <input type={"text"} width="350px" height="30px" style={{position:"absolute", right: "45px", borderRadius: "5px"}} placeholder="Search for a podcast" value={searchInput} 
           onChange={(e) => setSearchInput(e.target.value)}/>
          <IonTitle style={{width: "300px"}}>Podcast Recommendation</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen className='noSelect'>
        {searchInput === "" ? <IonCard>
          <IonCardHeader style={{ textAlign: "center" }}>
            <IonCardSubtitle>{allPodcasts[currentPodcast]?.podcast}</IonCardSubtitle>
            <IonCardTitle>{allPodcasts[currentPodcast]?.episode}</IonCardTitle>
          </IonCardHeader>
          <div className='podcastMedia noSelect'>
            <PlayBackCircleOutline height="15vh"
              width="15vw" color={"white"} onClick={prevPod} />
            <img style={{ width: "15vw", margin: "25px" }} src={allPodcasts[currentPodcast]?.thumbnail} />
            <PlayForwardCircleOutline height="15vh"
              width="15vw" color={"white"} onClick={nextPod} />
          </div>

          <div className='podcastMediaControls'>

            {isPlay ? <PlayCircleOutline onClick={() => setIsPlay(!isPlay)} height="10vh"
              width="10vw" color={"white"} /> : <PauseCircleOutline onClick={() => setIsPlay(!isPlay)} height="10vh"
                width="10vw" color={"white"} />}
          </div>
        </IonCard> : null}
        <IonList lines="none" className='suggestedPodcasts'>
          {podcasts.map(p => <PodcastItemList key={p.pid} podcast={p} />)}
        </IonList>
      </IonContent>
    </IonPage>
  );
};

export default Home;
