import {
  IonItem,
  IonLabel,
  } from '@ionic/react';
import { Podcast } from '../data/podcasts';
import './MessageListItem.css';

interface PodcastListItemProps {
  podcast: Podcast;
}

const PodcastItemList: React.FC<PodcastListItemProps> = ({ podcast }) => {
  return (
    <IonItem className="podcastContainer" routerLink={`/podcast/${podcast.pid}`} detail={false}>
      <IonLabel className="ion-text-wrap center-text">
        <img src={podcast.thumbnail} style={{width: "10vw", margin: "20px"}} />
        <h2>
          {podcast.podcast}
        </h2>
        <h3>{podcast.episode}</h3>
      </IonLabel>
    </IonItem>
  );
};

export default PodcastItemList;
