export interface Podcast {
    podcast: string;
    episode: string;
    thumbnail: string;
    pid: string;
}

const isLocalhost = Boolean(
    window.location.hostname === 'localhost' ||
      // [::1] is the IPv6 localhost address.
      window.location.hostname === '[::1]' ||
      // 127.0.0.0/8 are considered localhost for IPv4.
      window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
  );

  let URL: string;
  if(isLocalhost){
      URL = "http://localhost:8080"
  } else{
      URL = "https://podcast-recc-backend.fly.dev"
  }

export const getRecommendedPodcasts = async (pid: string, limit: number) => {
    const options = { method: 'GET' };   
    
    console.log(URL)
    let response = await fetch(`${URL}/recommend/${pid}?limit=${limit}`, options)
    let r = await response.json()
    let pods : Podcast[] = []
    Object.keys(r).forEach(pod => {
        pods.push(r[pod])
    })
    return pods
}

export const getAllPodcasts = async () => {
    const options = { method: 'GET' };  
    let response = await fetch(`${URL}/`, options)
    let r = await response.json()
    let pods : Podcast[] = []
    Object.keys(r).forEach(pod => {
        pods.push(r[pod])
    })
    return pods
}

export const getPodcast = async (pid:string) => {
    const options = { method: 'GET' };   
    let response = await fetch(`${URL}/podcast/${pid}`, options)
    let r = await response.json()
    return r

}

